import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { PRODUCT_ADJUSTABLE_BASE_2_ID } from 'gatsby-env-variables';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import { ReviewBodySection, ReviewHeroSection } from '../../components/common';
import { ReviewGrid } from '../../types/component';
import { Query } from '../../types/gatsby';
import { ReviewGraphQL } from '../../types/review';
import { Product } from '../../types/product';
import { SiteUrls } from '../../utils/constants';

const AdjustableBase2Review: React.FC = () => {
  const {
    allReviews: { edges: reviews },
    allProducts: { edges: products },
  } = useStaticQuery<Query<ReviewGraphQL | Product>>(graphql`
    query {
      allReviews(
        filter: { display: { eq: true } }
        sort: { fields: submittedOn, order: DESC }
      ) {
        edges {
          node {
            content
            display
            id
            media
            product {
              id
              name
            }
            rating
            submittedOn
            user {
              displayPic
              firstName
              lastName
            }
          }
        }
      }
      allProducts(filter: {}) {
        edges {
          node {
            id
            name
            rating {
              avg
              count
              total
            }
          }
        }
      }
    }
  `);

  const data: ReviewGrid[] = reviews
    .filter(
      ({ node }) =>
        (node as ReviewGraphQL).product.id === PRODUCT_ADJUSTABLE_BASE_2_ID
    )
    .map(({ node }) => {
      const reviewNode = node as ReviewGraphQL;
      return {
        displayPic: reviewNode.user.displayPic,
        name: `${reviewNode.user.firstName} ${reviewNode.user.lastName}`,
        rating: reviewNode.rating,
        content: reviewNode.content,
        submittedOn: reviewNode.submittedOn,
      };
    });
  const product = products.find(
    ({ node }) => node.id === PRODUCT_ADJUSTABLE_BASE_2_ID
  );
  const title = 'Woosa Adjustable Base II';

  return (
    <Layout>
      <SEO title={title} />
      {product && (
        <>
          <ReviewHeroSection
            type="AdjustableBase2"
            product={product.node as Product}
            title={title}
          />
          <ReviewBodySection
            data={data}
            title={title}
            product={product.node as Product}
            button={{
              text: 'Shop Woosa Adjustable Base II',
              url: SiteUrls.AdjustableBase2,
            }}
          />
        </>
      )}
    </Layout>
  );
};

export default AdjustableBase2Review;
